import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Typography } from '@mui/material';

import { SlidingArrowButton } from 'components/CustomButton';
import { studenstTestimonial } from './Constant';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(10),
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(15, 37.5),
        width: '100%',
        background: `
          linear-gradient(rgba(240, 253, 255, 0.8), rgba(240, 253, 255, 0.8)),
          url('https://assets.languify.in/images/b2cFrame.png') center center no-repeat
        `,
        backgroundSize: 'cover',
        backgroundBlendMode: 'overlay',
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(10, 15),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(10, 10),
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(10, 5),
        },
    },
    contentWrapper: {
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems : 'center',
        gap: theme.spacing(10),
    },
    cardContainer: {
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        overflow: 'hidden',
        position: 'relative'
      }
    },
    cardWrapper: {
      display: 'flex',
      gap: '20px',
      minWidth: 'fit-content',
      animation: '$scrollRightLoop 50s linear infinite',
      [theme.breakpoints.down('lg')]: {
        animation: '$scrollRightLoop 55s linear infinite',
      },
      [theme.breakpoints.down('md')]: {
        animation: '$scrollRightLoop 60s linear infinite',
      },
      [theme.breakpoints.down('sm')]: {
        animation: '$scrollRightLoop 65s linear infinite',
      },
      '&:hover': {
        animationPlayState: 'paused'
      }
  },
  '@keyframes scrollRightLoop': {
      '0%': {
          transform: 'translateX(0)'
      },
      '100%': {
          transform: 'translateX(-100%)'
      }
  },
    card: {
      flex: '0 0 calc(33.333% - 14px)', 
      maxWidth: '387px',
      minWidth: '300px',
      height: '400px',
      borderRadius: theme.spacing(5),
      border: `2px solid ${theme.palette.neutral['clr-300']}`,
      backgroundColor: theme.palette.shades['clr-white-900'],
      position: 'relative',
      padding: theme.spacing(10,6),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(8),
      [theme.breakpoints.down('lg')]: {
        flex: '0 0 300px', 
      }
    },
    vedioContainer: {
      width: '100%',
      display: 'flex',
      gap: '20px',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down("md")]: {
        flexWrap: 'wrap',
        justifyContent : 'center'
      },
    },
    pattern: {
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      },
    },
    '@keyframes scroll': {
      '0%': {
        transform: 'translateX(0)'
      },
      '100%': {
        transform: 'translateX(calc(-50% - 10px))' 
      }
    },
    profile : {
      borderRadius : '50%',
      border : `1px solid #8692A3`,
      width : '54px',
      height : '54px'
    },
}));

const StudentTestimonial = () => {
    const classes = useStyles();
    
    return (
        <Box className={classes.container}>
            <Box className={classes.contentWrapper}>
                <Box>
                    <Typography variant='h2-bold' color='b2c.dark-900'>
                        What Students say about us
                    </Typography>
                    <img 
                        className={classes.pattern} 
                        src='https://assets.languify.in/images/b2cPattern1.png' 
                        alt='img'
                    />
                </Box>
                <Box display='flex' flexDirection='column' gap='20px' width="100%">
                    <Box className={classes.cardContainer}>
                        <Box className={classes.cardWrapper}>
                            {[...studenstTestimonial, ...studenstTestimonial].map((data, index) => (
                                <Box key={index} className={classes.card}>
                                    <img 
                                        style={{
                                            borderRadius: '20px',
                                            position: 'absolute',
                                            top: 0,
                                            right: -1
                                        }}
                                        src='https://assets.languify.in/images/b2cpattern2.png'
                                        alt="pattern"
                                    />
                                   <Box display='flex' gap='12px'>
                                    {data?.image ? (
                                      <img src={data.image} alt='profile' className={classes.profile}/>
                                    ):
                                    (
                                      <AccountCircleRoundedIcon 
                                          fontSize="large" 
                                          className={classes.profile} 
                                          style={{ color: '#8692A3' }} 
                                      />
                                    )}
                                      
                                      <Box display='flex' flexDirection='column' gap='4px'>
                                        <Typography variant='h6-bold' color='b2c.dark-900'>
                                          {data.studentName}
                                        </Typography>
                                        <Typography variant='body01-medium' color='neutral.clr-600'>
                                          {data.institueName}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Typography variant='body01-bold' color='b2c.dark-900'>
                                        {data.testimonial}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Typography variant='h2-bold' color='b2c.dark-300' style={{textAlign : 'center'}}>
                    Get Interview Ready in 30 Minutes!
                </Typography>
                <SlidingArrowButton
                    label="Start Preparing Now"
                    bgColor='#1961BF'
                    variant="outlined"
                    width="291px"
                    sx={{ textTransform: 'none' }}
                    onClick={() => {
                      const element = document.getElementById("pricing");
                      if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                      }
                  }}
                />
            </Box>
        </Box>
    );
};

export default StudentTestimonial;