export const studenstTestimonial = [
  {
    _id: 1,
    studentName: 'Amey Patil',
    institueName: 'MBA Student at SP Jain',
    image: 'https://assets.languify.in/images/amey_spjain.png',
    testimonial: `The platform has a clean and intuitive UI, making speaking practice seamless and effective.
     Its user-friendly design ensures a smooth experience, helping users improve their fluency and confidence.
      The structured approach enhances practice sessions, making them more engaging and productive.`
  },
  {
    _id: 2,
    studentName: 'Chirag Vadara',
    institueName: 'MBA Student at SP Jain',
    image: 'https://assets.languify.in/images/chirag_spjain.png',
    testimonial: `I liked the AI-based structuring, which makes the learning process more personalized and effective. The test is highly effective in evaluating knowledge, providing valuable insights into areas for improvement and helping users track their progress.`
  },
  {
    _id: 3,
    studentName: 'Pratik Aggarwal',
    institueName: 'MBA Student at SP Jain',
    image: 'https://assets.languify.in/images/pratik_spjain.png',
    testimonial: `It's a no-brainer—just give it a try! Along with insightful feedback, the camera and interview simulation within a set time period help you practice repeatedly, allowing you to become more natural and confident with each session.`
  },
  {
    _id: 4,
    studentName: 'Dhruv Patel',
    institueName: 'IIT Bombay',
    testimonial: `The tool was quite helpful. It helped me boost my confidence, and because of this confidence, I was able to communicate my skills. I was able to communicate my experiences, and that is what interviews are about—telling what all things you did in the past, what all things you know. And yeah, the tool helped me in doing this thing better.`
  },
  {
    _id: 5,
    studentName: 'Jayanth',
    institueName: 'MBA Student at Great Lakes Institute of Management ',
    testimonial: `Helped me in presenting & articulation of my answers. I just uploaded the job description and it created an interview for me, later I also went through the feedback of how I could have improved my answers.
Feedback was really nice, it was able to capture my wordings and filler correctly and helped me improve. This can be used to practice multiple mock interviews.`
  },
  {
    _id: 5,
    studentName: 'Sanjay Sarkar',
    institueName: 'MBA Student',
    image : 'https://languify-assets-v02.s3.ap-south-1.amazonaws.com/images/sanjay.png',
    testimonial: `The platform is pretty comprehensive I have to say, in terms of questions asked and the user interface was also good.
The feedback generated post the interview too was very detailed, every question had a detailed analysis followed by an expected/suggested answer, which I feel is the most important par`
  }
]

export const productBasedImages = [
  'https://assets.languify.in/images/amazon.svg',
  'https://assets.languify.in/images/google.svg',
  'https://assets.languify.in/images/meta.svg',
  'https://assets.languify.in/images/netflix.svg',
  'https://assets.languify.in/images/spotify.svg',
  'https://assets.languify.in/images/paypal.svg',
  'https://assets.languify.in/images/flipkart.svg',
];

export const serviceBasedImages = [
  'https://assets.languify.in/images/infosys.svg',
  'https://assets.languify.in/images/tcs.svg',
  'https://assets.languify.in/images/capgemini.svg',
  'https://assets.languify.in/images/accenture.svg',
  'https://assets.languify.in/images/wipro.svg',
  'https://assets.languify.in/images/deloitte.svg',
  'https://assets.languify.in/images/hcl.svg',
  'https://assets.languify.in/images/techMahindra.svg',
  'https://assets.languify.in/images/hexaware.svg',
];

export const clientImages = [
  'https://assets.languify.in/images/SPJ_b2c.png',
  'https://assets.languify.in/images/Aisect_b2c.png',
  'https://assets.languify.in/images/IIMU_b2c.png',
  'https://assets.languify.in/images/IIDE_b2c.png',
  'https://assets.languify.in/images/Somaiya_b2c.png',
]

export const useCase = [
  {
    content: 'Final-year students preparing for placements can practice personalized mock interviews based on the company, job descriptions and their resume.',
    img: 'https://assets.languify.in/images/b2cRaj.svg'
  },
  {
    content: 'Got an interview call but feel unprepared? Get interview-ready with AI-powered Interview Wizard designed just for you!',
    img: 'https://assets.languify.in/images/b2cRaj1.svg'
  }
]

export const mockSteps = [
  {
    name: "Job description & Resume",
    title: 'Company & Resume based Personalized Interview',
    steps: [
      {
        title: "Select Job Details",
        img: 'https://assets.languify.in/images/JD_Resume_1.svg'
      },
      {
        title: "Select Interview Round",
        img: 'https://assets.languify.in/images/JD_Resume_2.svg'
      },
      {
        title: "Select Interviewer for Questions Difficulty",
        img: 'https://assets.languify.in/images/JD_Resume_3.svg'
      },
      {
        title: "Upload Your Resume",
        img: 'https://assets.languify.in/images/JD_Resume_4.svg'
      },
      {
        title: "Preview Details & Begin Interview",
        img: 'https://assets.languify.in/images/JD_Resume_5.svg'
      },
    ],
  },
  {
    name: "Job description only",
    title: 'Company focused Interview without Resume',
    steps: [
      {
        title: "Select Job Details",
        img: 'https://assets.languify.in/images/JD_1.svg'
      },
      {
        title: "Select Interview Round",
        img: 'https://assets.languify.in/images/JD_2.svg'
      },
      {
        title: "Select Interviewer for Questions Difficulty",
        img: 'https://assets.languify.in/images/JD_3.svg'
      },
      {
        title: "Preview Details & Begin Interview",
        img: 'https://assets.languify.in/images/JD_4.svg'
      },
    ],
  },
  {
    name: "Resume only",
    title: 'Job Role focused Interview based on Resume only',
    steps: [
      {
        title: "Select Job Details",
        img: 'https://assets.languify.in/images/Resume_1.svg'
      },
      {
        title: "Select Interview Round",
        img: 'https://assets.languify.in/images/Resume_2.svg'
      },
      {
        title: "Select Interviewer for Questions Difficulty",
        img: 'https://assets.languify.in/images/Resume_3.svg'
      },
      {
        title: "Upload Your Resume",
        img: 'https://assets.languify.in/images/Resume_4.svg'
      },
      {
        title: "Preview Details & Begin Interview",
        img: 'https://assets.languify.in/images/Resume_5.svg'
      },
    ],
  },
  {
    name: "Report based",
    title: "Practice Interview Questions based on Report",
    steps: [
      {
        title: "Select Report details",
        img: 'https://assets.languify.in/images/Report_1.svg'
      },
      {
        title: "Add Report (Upload PDF/Paste Content)",
        img: 'https://assets.languify.in/images/Report_3.svg'
      },
      {
        title: "Select Interviewer for Questions Difficulty",
        img: 'https://assets.languify.in/images/Report_4.svg'
      },
      {
        title: "Preview Details & Begin Interview",
        img: 'https://assets.languify.in/images/Report_5.svg'
      },
    ],
  },
];

export const feedbackSteps = [{
  name: 'Feedback Steps',
  title: 'Students will get Feedback over 15+ Parameters',
  steps: [
    {
      title: 'Voice Feedback by our AI-Interviewers.',
      img: 'https://assets.languify.in/images/Feedback_1.svg'
    },
    {
      title: 'Detailed Speech & Content Parameters Feedback.',
      img: 'https://assets.languify.in/images/Feedback_2.svg'
    },
    {
      title: 'Improvement Suggestions on Student’s Response.',
      img: 'https://assets.languify.in/images/Feedback_3.svg'
    },
    {
      title: 'Example response for Situational Questions.',
      img: 'https://assets.languify.in/images/Feedback_4.svg'
    },
    {
      title: 'Ideal response for Technical Questions.',
      img: 'https://assets.languify.in/images/Feedback_5.svg'
    },
  ]
}]